/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {},
      finalize: function() {
        $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').not('[data-toggle]').click(function(event) {
          if (
            location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')  &&
            location.hostname === this.hostname ) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              event.preventDefault();
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 1200, function() {
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) {
                  return false;
                } else {
                  $target.attr('tabindex','-1');
                  $target.focus();
                }
              });
            }
          }
        });

        // Wordpress Gallery
        $('.wysiwyg .gallery').each(function(){
          $(this).slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              prevArrow: '<button type="button" class="slick-prev"><i class="icon-right-arrow"></i></button>',
              nextArrow: '<button type="button" class="slick-next"><i class="icon-right-arrow"></i></button>',
              paging: false,
              adaptiveHeight: false,
              dots: false,
              fade: false,
              infinite: true,
          });
        });

        if(typeof popup !== 'undefined' && popup === 1 && $.session.get('modal-already-popped') === '1' && typeof front == 'undefined' ) {
            return false;
        } else{
            if($('#alertModal').length){
                $.session.set('modal-already-popped', '1');
                setTimeout( function(){
                    $('#alertModal').modal('show');
                }, 1500);
            }
        }

        // Init responsive menu
        if($( '#dl-menu' ).length > 0){
            $( '#dl-menu' ).dlmenu({
                animationClasses : { classin : 'dl-animate-in-2', classout : 'dl-animate-out-2' }
            });
        }

        // Newsletter
        $('.wpcf7-submit').on('click', function(){
            $(this).addClass("loading");
        });

        var disableLoadingAnim = function (event) {
            $(".form-newsletter .wpcf7-submit").removeClass("loading");
        };

        var wpcf7Elm = document.querySelector( '.footer-content' ).querySelector( '.newsletter-form--wrapper' );
        if(wpcf7Elm){
            wpcf7Elm = wpcf7Elm.querySelector( '.wpcf7' );
            wpcf7Elm.addEventListener('wpcf7invalid', disableLoadingAnim, false);
            wpcf7Elm.addEventListener('wpcf7spam', disableLoadingAnim, false);
            wpcf7Elm.addEventListener('wpcf7mailfailed', disableLoadingAnim, false);
            wpcf7Elm.addEventListener('wpcf7mailsent', function( event ) {
                $(".form-newsletter .wpcf7-submit").removeClass("loading");
                $('#modalNewsletter').modal('show');
            }, false );
        }

        $('#modalNewsletter').on('show.bs.modal', function(){
            $('body').addClass("newsletterBackdrop");
        });
        $('#modalNewsletter').on('hidden.bs.modal', function(){
            $('body').removeClass("newsletterBackdrop");
        });
      }
    },
    'home': {
      init: function() {},
      finalize: function() {}
    },
    'page_template_template_equipements': {
      finalize: function() {
        $('.equipement-gallery__slides').each(function(){
            $(this).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow:$(this).parent().find('.slick-prev'),
                nextArrow:$(this).parent().find('.slick-next'),
                dots: false,
                fade: false,
                infinite: true,
            });
        });
      }
    },
    'page_template_template_activites': {
        finalize: function() {
          $('.activity-gallery__slides').each(function(){
              $(this).slick({
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  prevArrow:$(this).parent().find('.slick-prev'),
                  nextArrow:$(this).parent().find('.slick-next'),
                  dots: false,
                  fade: false,
                  infinite: true,
              });
          });
        }
      }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
